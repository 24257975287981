export default {
  token: "",
  showToast: false,
  warningText: "",
  isNomadic: false,
  promoList: [],
  locationList: [],
  userData: {},
  userCurrentStatus: "",
  mopCardData: {},
  propsMeetingData: {},
  meetingsData: [],
  employeesData: [],
  employeesDataRaw: [],
  locationId: null,
  mopTeamId: null,
  activeTab: "",
  meetingTableHeaderName: [
    "Проект",
    "Телефон",
    "Сделка",
    "Язык",
    "Этап",
    "Дата",
    "Сотрудник",
    "Действие",
  ],
  employeesTableHeaderName: [
    "Сотрудник",
    "Окно",
    "План",
    "Статус",
    "Команда ОП",
    "Показов",
    "Язык",
    "Номад",
    "Дата выходного",
  ],
  adminTableHeaders: ["Сотрудник", "Действие"],
  adminTableTeamHeaders: ["Отдел продаж", "Действие"],
  adminTableLocationHeaders: ["Локация", "Действие"],
  isMopBusy: false,
  selectEmployeesList: [],
  salesTeamDropdownList: [],
  meetingMetricsData: [],
  employeesMetricsData: [],
  activeMeetingStatus: ["new", "in_progress", "on_presentation"],
  disabledActionStatus: ["canceled", "completed"],
  disabledUserStatus: ["canceled", "completed", "on_presentation"],
  addUserFormData: [],
  addTeamFormData: [],
  addLocationFormData: [],
  usersFieldData: [],
  projectsFormData: [],
  addTeamParams: {},
  addLocationParams: {},
  addressList: [],
  teamsForLocationFormData: [],
  addUserFormDataParams: { type: "create" },
  addTeamFormDataParams: { type: "create" },
  addLocationFormDataParams: { type: "create" },
  userDataInAdminTable: [],
  teamDataInAdminTable: [],
  locationDataInAdminTable: [],
  propsUserDataInAdminTable: [],
  propsTeamDataInAdminTable: [],
  propsLocationDataInAdminTable: [],
  meetingsActionList: [
    {
      id: "completed",
      fontIcon: "n",
      background: "#E7F6EC",
      color: "#0F973D",
      tooltipText: "Встреча завершена",
    },
    {
      id: "canceled",
      fontIcon: "m",
      background: "#FBEAE9",
      color: "#D42620",
      tooltipText: "Встреча отменена",
    },
    {
      id: "on_presentation",
      fontIcon: "u",
      background: "#F0ECFD",
      color: "#2256E3",
      tooltipText: "На выезде",
    },
  ],
  meetingsActionListCsm: [
    {
      id: "completed",
      fontIcon: "n",
      background: "#E7F6EC",
      color: "#0F973D",
      tooltipText: "Встреча завершена",
    },
    {
      id: "canceled",
      fontIcon: "m",
      background: "#FBEAE9",
      color: "#D42620",
      tooltipText: "Встреча отменена",
    },
  ],
  adminActionList: [
    {
      id: "edit",
      fontIcon: "x",
      background: "#E7F6EC",
      color: "#0F973D",
      tooltipText: "Редактировать",
    },
  ],
  locationActionList: [
    {
      id: "edit",
      fontIcon: "x",
      background: "#E7F6EC",
      color: "#0F973D",
      tooltipText: "Редактировать",
    },
    {
      id: "info",
      fontIcon: "а",
      background: "#E9EEFC",
      color: "#2256E3",
      tooltipText: "Информация",
    },
  ],
  userRole: "",
  userRoleDic: {
    marketing: {
      id: 5,
      name: "Сотрудник отдела маркетинга",
    },
    msd: {
      id: 4,
      name: "Менеджер отдела продаж",
    },
    csm: {
      id: 7,
      name: "Менеджер отдела клиенского сервиса",
    },
    hsd: {
      id: 3,
      name: "Руководитель отдела продаж",
    },
    om: {
      id: 2,
      name: "Администратор отдела продаж",
    },
    admin: {
      id: 1,
      name: "Супер администратор",
    },
    saop: {
      id: 6,
      name: "Старший администратор отдела продаж",
    },
  },
  nomadDic: {
    0: {
      id: 0,
      name: "Штат",
    },
    1: {
      id: 1,
      name: "Номад",
    },
  },
  langDic: {
    1: {
      name: "KZ",
      id: 1,
      photo: require("@/assets/Kazakh_language.svg"),
    },
    2: {
      name: "RU",
      id: 2,
      photo: require("@/assets/Russian_language.svg"),
    },
    3: {
      name: "Все языки",
      id: 3,
      photo: require("@/assets/mix.svg"),
    },
  },
  mopButtonData: [
    {
      type: "large",
      text: "Принять следующего",
      src: require("@/assets/right-arrow.svg"),
      params: {
        status: "completed",
      },
      action: "confirm",
      confirmTitle: "Выберите тип встречи",
      confirmText:
        "Ваша сделка автоматический перенесется на нужный этап в Битрикс24",
      confirmImg: require("@/assets/check.svg"),
      confirmImgBg: "#E9EEFC",
      confirmButtonBg: "#2256E3",
      show: true,
      onlyForMop: false,
    },
    {
      type: "small",
      text: "Завершить и отойти",
      src: require("@/assets/rest.svg"),
      params: {
        status: "completed_and_finish",
      },
      action: "confirm",
      confirmTitle: "Завершить и отойти?",
      confirmText:
        "Это действие безвозвратно. Закрепленная за вами очередь будет распределена между свободными менеджерами.",
      confirmImg: require("@/assets/cup-fill.svg"),
      show: true,
      onlyForMop: false,
    },
    {
      type: "small",
      text: "Выезд на объект",
      src: require("@/assets/auto.svg"),
      params: {
        status: "on_presentation",
      },
      confirmTitle: "Вы хотите выехать на объект?",
      confirmText:
        " Закрепленная за вами очередь будет распределена между свободными менеджерами.",
      confirmImg: require("@/assets/cup-fill.svg"),
      action: "confirm",
      show: true,
      onlyForMop: true,
    },
    {
      type: "small",
      text: "Отменить встречу",
      src: require("@/assets/cancel.svg"),
      params: {
        status: "canceled",
      },
      action: "confirm",
      confirmTitle: "Отменить встречу",
      confirmText:
        "Это действие безвозвратно. Вы уверены, что хотите отменить назначеную встречу?",
      confirmImg: require("@/assets/delete-bin-fill.svg"),
      confirmImgBg: "#FEF4E6",
      confirmButtonBg: "#DD3409",
      show: true,
      onlyForMop: false,
    },
  ],
  userStatusDic: {
    active: "Онлайн",
    inactive: "Офлайн",
    blocked: "Офлайн",
    busy: "Онлайн",
    day_out: "Офлайн",
    on_presentation: "Онлайн",
  },
  menuList: [
    {
      icon: "b",
      name: "meetings",
      text: "Текущие встречи",
      id: 1,
      active: false,
      role: ["hsd", "om", "admin", "msd", "saop", "csm"],
    },
    {
      icon: "c",
      name: "employees",
      text: "Сотрудники",
      id: 2,
      active: false,
      role: ["hsd", "om", "admin", "saop"],
    },
    {
      icon: "e",
      name: "superAdmin",
      type: "addUser",
      text: "Добавить сотрудника",
      id: 3,
      active: false,
      role: ["admin", "saop"],
    },
    {
      icon: "f",
      name: "superAdmin_teams",
      type: "addTeam",
      text: "Добавить ОП",
      id: 4,
      active: false,
      role: ["admin"],
    },
    {
      icon: "g",
      name: "superAdmin_location",
      type: "addLocation",
      text: "Добавить локацию",
      id: 5,
      active: false,
      role: ["admin"],
    },
    {
      icon: "h",
      name: "marketingAddPromo",
      type: "addPromo",
      text: "Создать промо",
      id: 6,
      active: false,
      role: ["admin", "marketing"],
    },
    {
      icon: "i",
      name: "marketingMyPromo",
      type: "myPromo",
      text: "Мои промо",
      id: 7,
      active: false,
      role: ["admin", "marketing"],
    },
  ],
};
